import React, { useEffect, useRef, useState } from "react";
import Main from "./Main";
import Learn from "./Learn";
import Trend from "./Trend";
import Popular from "../../components/Popular";
import Download from "./Download";
import News from "../../components/News";
import Steps from "./Steps";
import requestHandler from "../../actions/httpClient";
import MaintenaceNotice from "../../components/MaintenaceNotice";

const Home = () => {
  const scrollToRef = useRef(null);
  const [marketsData, setMarketsData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getRegion();
  }, []);

  const getRegion = async () => {
    setLoading(true);
    try {
      const regionPayload = await requestHandler("region", "post");
      setLoading(false);
      for (let i = 0; i < regionPayload.data?.data.length; i++) {
        if (regionPayload?.data?.data[i].slug === 'eur') {
          getMarkets(regionPayload?.data?.data[i]?.id);
        }
      }
    }
    catch (e) {
      setLoading(false);
    };
  };

  const getMarkets = async (id) => {
    try {
      setLoading(true)
      let data = {
        type: "randomMarkets",
        region: id,
      };
      const homePagePayload = await requestHandler("get_markets", "post", data);
      setMarketsData(homePagePayload?.data?.data);
      setLoading(false);
    }
    catch (e) {
      setLoading(false);
    };
  };

  return (
    <>
      {process.env.REACT_APP_MAINTENANCE_MODE == 0 && (
        <MaintenaceNotice />
      )}
      <Main scrollToRef={scrollToRef} marketsData={marketsData} loading={loading} />
      <Trend marketsData={marketsData} loading={loading} />
      <Learn scrollToRef={scrollToRef} />
      <Popular classSection="section-bg section-mb0" />
      <Download />
      <News classSection="section-bg" />
      <Steps />
    </>
  );
};



export default Home;
