import React, { useEffect, useState } from "react";
import Main from "./Main";
import Details from "./Details";
import requestHandler from "../../actions/httpClient";

const Market = () => {

  const [allMarketsData, setAllMarketsData] = useState([]);
  const [dataMarkets, setDataMarkets] = useState([]);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getRegion();
  }, []);

  const getRegion = async () => {
    setLoading(true);
    try {
      const regionPayload = await requestHandler("region", "post");
      setLoading(false);
      for (let i = 0; i < regionPayload.data?.data.length; i++) {
        if (regionPayload?.data?.data[i].slug == 'eur') {
          getAllMarkets(regionPayload?.data?.data[i]?.id);
        }
      }
    }
    catch (e) {
      setLoading(false);
    };
  };

  const getAllMarkets = async (id) => {
    try {
      setLoading(true)
      let data = {
        type: "allMarkets",
        region: id,
      };
      const marketsPayload = await requestHandler("get_markets", "post", data);
      const sortedMarkets = marketsPayload?.data?.data[0]?.markets?.sort((a, b) => a.market_position - b.market_position);
      setAllMarketsData(marketsPayload?.data?.data);
      setDataMarkets(sortedMarkets);
      setLoading(false)
    }
    catch (e) {
      setLoading(false)
    };
  };

  return (
    <>
      <Main />
      <Details
        allMarketsData={allMarketsData}
        loading={loading}
        dataMarkets={dataMarkets}
        setDataMarkets={setDataMarkets}
      />
    </>
  );
};

export default Market;
