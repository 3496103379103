import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Releases.module.sass";

const Releases = ({ scrollToRef }) => {

  return (
    <div
      className={cn("section-bg section-mb0", styles.releases)}
      ref={scrollToRef}
    >
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <div className={styles.wrap}>
            <h2 className={cn("h2", styles.title)}>BDNX release information</h2>
            {/* <p className={styles.text}>Secure, convenient, and connecting crypto enthusiasts worldwide</p> */}
          </div>
        </div>
        <table className={styles.customtable}>
          <tbody>
            <tr className={styles.rowlight}>
              <th>Token name</th>
              <td>{process.env.REACT_APP_TOKEN_NAME}</td>
            </tr>
            <tr className={styles.rowlight}>
              <th>Token symbol</th>
              <td>{process.env.REACT_APP_TOKEN_SYMBOL}</td>
            </tr>
            <tr className={styles.rowlight}>
              <th>Blockchain</th>
              <td>{process.env.REACT_APP_TOKEN_CHAIN}</td>
            </tr>
            <tr className={styles.rowlight}>
              <th>Contract address</th>
              <td>{process.env.REACT_APP_TOKEN_CONTRACT_ADDRESS}</td>
            </tr>
            <tr className={styles.rowlight}>
              <th>Issue price</th>
              <td>{process.env.REACT_APP_TOKEN_ISSUE_PRICE}</td>
            </tr>
            <tr className={styles.rowlight}>
              <th>Circulating supply</th>
              <td>{process.env.REACT_APP_TOKEN_CIRCULATING_SUPPLY}</td>
            </tr>
            <tr className={styles.rowlight}>
              <th>Total supply</th>
              <td>{process.env.REACT_APP_TOKEN_TOTAL_SUPPLY}</td>
            </tr>
            <tr className={styles.rowlight}>
              <th>Launch date</th>
              <td>{process.env.REACT_APP_TOKEN_LAUNCH_DATE}</td>
            </tr>
          </tbody>
        </table>

        <div className={styles.thirdcontainer}>
          <div className={styles.leftcolumn}>
            <div className={styles.card}>
              <div className={styles.cardcontent}>
                <h2 className={cn("h4", styles.titleHeading)}>Team incentive</h2>
                <p>4% Release rate per year.</p>
              </div>
              <div className={styles.secondcardcontent}>
                <h2 className={cn("h4", styles.titleHeading)}>20%</h2>
                <p>Being released.</p>
              </div>
            </div>
          </div>
          <div className={styles.middlecolumn}>
            <div className={styles.card}>
              <div className={styles.cardcontentsmall}>
                <h2 className={cn("h4", styles.titleHeading)}>Branding</h2>
                <p>35% Being released.</p>
                <p>5% Release rate per year.</p>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.cardcontentsmall}>
                <h2 className={cn("h4", styles.titleHeading)}>Referrals</h2>
                <p>10% Being released.</p>
                <p>2% Release rate per year.</p>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.cardcontentsmall}>
                <h2 className={cn("h4", styles.titleHeading)}>BDNX ecosystem</h2>
                <p>15% Released to the investment fund.</p>
              </div>
            </div>
          </div>
          <div className={styles.rightcolumn}>
            <div className={styles.bigcardcard}>
              <div className={styles.cardcontent}>
                <h2 className={cn("h4", styles.titleHeading)}>User input</h2>
                <p>5% Release rate per year.</p>
              </div>
              <div className={styles.secondcardcontent}>
                <h2 className={cn("h4", styles.titleHeading)}>10%</h2>
                <p>Being released.</p>
              </div>
            </div>
            <div className={styles.smallcardcard}>
              <div className={styles.cardcontentsmall}>
                <h2 className={cn("h4", styles.titleHeading)}>Protection fund</h2>
                <p>10% Released to compensate for unforeseen incidents.</p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.details}>
          <Link className={cn("button", styles.button)} to='/sign-in'>
            Get BDNX
          </Link>
          <p className={styles.note}>Let's build a safe, reliable and secure Bitdenex ecosystem and contribute to the development of the Crypto world. </p>
        </div>
      </div>
    </div>
  );
};

export default Releases;
