import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./Form.module.sass";
import TextInput from "../../../components/TextInput";
import Icon from "../../../components/Icon";
import SimpleReactValidator from "simple-react-validator";
import Loader from "../../../components/Loader";
import Checkbox from "../../../components/Checkbox";
import { io } from "socket.io-client";

const Form = ({ sessionId, loginHandler, signInLoading, captcha, setReCaptcha, reCaptcha, captchaLoading,
  qrCodeData, getQrCodeHandler, qrCodeLogin, setQrCodeLogin, showQrCode, setShowQrCode, count, setCount,
  setSessionId }) => {
  // initializing the states and variables
  const [, forceUpdate] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const simpleValidator = useRef(new SimpleReactValidator());
  const [passwordType, setPasswordType] = useState("password");
  const base64data = btoa(unescape(encodeURIComponent(captcha?.buffer)));


  // function to change the state of eye icon in password field(hide and show)
  const togglePassword = (e) => {
    e.preventDefault();
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    };
    setPasswordType("password")
  };

  useEffect(() => {
    getQrCodeHandler();
  }, []);

  const handleQrLogin = () => {
    // getQrCodeHandler();
    setShowQrCode(true);
    setQrCodeLogin(!qrCodeLogin);
    // var socket = io(process.env.REACT_APP_SOCKET_URL);
    // socket.on("connect", () => {
    //   console.log("socket", socket, socket.id);
    // });
    // socket.on('loginWebhook_' + sessionId, function (result) {
    //   if (result[0].signature) {
    //     var signature = result[0].signature;
    //     signature = signature.replace('+', '_');
    //     signature = signature.replace('/', '-');
    //     window.location.href = result[0].url + '?sig=' + signature;
    //   }
    // });
  };

  useEffect(() => {
    if (qrCodeLogin) {
      const refreshInterval = setInterval(() => {
        setCount((prevCount) => prevCount + 1);
      }, 1000);

      if (count === 60) {
        clearInterval(refreshInterval);
        setShowQrCode(false);
      }
      return () => clearInterval(refreshInterval);
    }
  }, [count, qrCodeLogin]);

  return (
    <>
      {!qrCodeLogin ?
        <>
          <div className={styles.login}>
            <div className={styles.top}>
              <h4 className={cn("h4", styles.title)}>Sign in to Bitdenex</h4>
              <div className={styles.info}>
                Please ensure you are visiting the correct url.
              </div>
              <div className={styles.correct}>
                <Icon name="lock" size="24" />
                <div className={styles.url}>
                  https://<span>bitdenex.com/sign-in</span>
                </div>
              </div>
            </div>
            <form className={styles.form} onSubmit={(e) => { e.preventDefault() }}>
              <div className={styles.container}>
                <Checkbox
                  value={qrCodeLogin}
                  content="Log in with QR code"
                  onChange={handleQrLogin}
                />
              </div>
              <div className={styles.container}>
                <TextInput
                  className={styles.field}
                  label="email"
                  name="email"
                  type="email"
                  placeholder="Email address"
                  required
                  value={email}
                  onChange={(e) => { setEmail(e.target.value); }}
                  onBlur={() => simpleValidator.current.showMessageFor("email")}
                />
                <span className={styles.danger}>
                  {simpleValidator.current.message("email", email, "required|email")}
                </span>
              </div>
              <TextInput
                className={styles.field}
                label="Password"
                name="password"
                type={passwordType}
                placeholder="Password"
                value={password}
                onChange={(e) => { setPassword(e.target.value); }}
                required
                view
                togglePassword={togglePassword}
                onBlur={() => simpleValidator.current.showMessageFor("password")}
              />
              <span className={styles.danger}>
                {simpleValidator.current.message("password", password, "required|min:9")}
              </span>

              <div className={styles.foot}>
                <Link className={styles.link} to="/forgot-password">
                  Forgot password?
                </Link>
              </div>
              <div className={styles.customImage}>
                {!captchaLoading ? <img className={styles.captcha} src={`data:image/svg+xml;base64,${base64data}`} alt="" /> : <Loader className={styles.loader} />}
              </div>
              <TextInput
                className={styles.field}
                name="captcha"
                placeholder="Enter captcha here"
                value={reCaptcha}
                onChange={(e) => { setReCaptcha(e.target.value); }}
                required
                onBlur={() => simpleValidator.current.showMessageFor("captcha")}
              />
              <span className={styles.danger}>
                {simpleValidator.current.message("captcha", reCaptcha, "required")}
              </span>

              <button
                className={cn("button", styles.button)}
                disabled={signInLoading}
                onClick={(e) => {
                  e.preventDefault();
                  if (simpleValidator.current.allValid()) {
                    loginHandler(email, password, captcha?.captchaId);
                  }
                  else {
                    simpleValidator.current.showMessages();
                    forceUpdate(1);
                  }
                }
                }>Sign in</button>
            </form>
          </div></> :
        <>
          <div className={styles.login}>
            <div className={styles.top}>
              <h4 className={cn("h4", styles.title)}>Sign in to Bitdenex</h4>
              <div className={styles.info}>
                Please ensure you are visiting the correct url.
              </div>
              <div className={styles.correct}>
                <Icon name="lock" size="24" />
                <div className={styles.url}>
                  https://<span>bitdenex.com/sign-in</span>
                </div>
              </div>
            </div>
            <form className={styles.form} onSubmit={(e) => { e.preventDefault() }}>
              <div className={styles.checkboxContainer}>
                <Checkbox
                  value={qrCodeLogin}
                  content="Log in with QR code"
                  onChange={() => { setQrCodeLogin(!qrCodeLogin); setCount(0); }}
                />
              </div>
              {/* <QRCode value={qrCodeData} width={40} height={40} /> */}
              <img className={showQrCode ? styles.image : styles.hideBarCode} src={qrCodeData} alt="icon" />
              <div className={styles.boxContainer}>
                <div className={styles.textClass}>
                  {/* Scan this code with the <a href="https://bitdenex.com/bitdenex-app" target="_blank" className={styles.linkText}>Bitdenex mobile app</a> to log in instantly. */}
                  <strong>Step 1.</strong> Download the latest <a href="https://bitdenex.com/bitdenex-app" target="_blank" className={styles.linkText}>Bitdenex app</a>, or update the Bitdenex app with the latest version.
                </div>
                <div className={styles.textClass}>
                  <strong>Step 2.</strong> Log in with your Bitdenex login credentials, scan this QR-code and confirm your login.
                </div>
              </div>

              {!showQrCode &&
                <>
                  <div className={styles.expiredText}>QR code expired</div>
                  <button className={cn("button-small", styles.button)}
                    onClick={() => {
                      setShowQrCode(true);
                      setSessionId("");
                      setCount(0);
                      getQrCodeHandler();
                    }}>Refresh QR code</button>
                </>}
            </form>
          </div>
        </>
      }
    </>
  );
};

export default Form;
